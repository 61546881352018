<template>
  <div class="mobilemenu">
    <ul class="sidemenu">
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Нүүр'">
        <router-link to="/">
          <i class="isax isax-home"></i>
          <span>Нүүр</span>
        </router-link>
      </li>
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Жанр'">
        <router-link to="/media">
          <i class="isax isax-video-circle"></i>
          <span>Контент</span>
        </router-link>
      </li>
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Дүр'">
        <a href="https://bloombergtv.mn" target="_blank">
          <i class="isax isax-global"></i>
          <span>Мэдээ</span>
        </a>
      </li>
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Дүр'">
        <router-link to="/news-latter">
          <i class="isax isax-archive-book4"></i>
          <span>Товхимол</span>
        </router-link>
      </li>
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Нас'">
        <router-link to="/podcast">
          <i class="isax isax-microphone"></i>
          <span>Подкаст</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import useHeaderRoute from "@hooks/useHeaderRoute";
import {mapGetters} from 'vuex';
import Logo from "../../assets/logo.png";
import LogoMini from "../../assets/logo.png";

export default {
  setup() {
    const {currentRoute, routes} = useHeaderRoute();

    return {
      currentRoute,
      routes,
    };
  },
  data() {
    return {
      Logo,
      LogoMini
    }
  },
  computed: {
    ...mapGetters(['showMenu'])
  }
};
</script>
