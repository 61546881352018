<template>
  <div class="page">
    <div class="page-header">
      <div class="header-layer">
        <div class="container">
          <div class="header-title">
            <h3>Түгээмэл асуулт</h3>
            <span class="divider"></span>
            <div class="header-breadcrumb">
              <router-link to="/">
                <span>Эхлэл</span>
                <i class="isax isax-arrow-right-4"></i>
              </router-link>

              <router-link to="/faq" class="current">
                <span>Түгээмэл асуулт</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="faq-page">
        <div v-if="faq.length > 0" class="accordion">
          <div v-for="(item, index) in faq" :key="index"
               :class="`accordion-item ${activeIndex == item.id ? 'open' : ''}`">
            <button :id="`accordion-button-${item.id}`" @click="toggleAccordion(item.id)"
                    :aria-expanded="activeIndex == item.id ? true : false">
              <span class="accordion-title"><strong>{{ index + 1 }}.</strong> {{ item.title }}</span><span
                class="icon" aria-hidden="true"></span></button>
            <div class="accordion-content">
              <p v-html="item.body"></p>
            </div>
          </div>
        </div>

        <div v-if="faq.length > 0" class="accordion">
          <div v-for="(item, index) in faq" :key="index"
               :class="`accordion-item ${activeIndex == (item.id + 1) ? 'open' : ''}`">
            <button :id="`accordion-button-${item.id + 1}`" @click="toggleAccordion(1 + item.id)"
                    :aria-expanded="activeIndex == (item.id + 1) ? true : false">
              <span class="accordion-title"><strong>{{ index + 1 }}.</strong> {{ item.title }}</span><span
                class="icon" aria-hidden="true"></span></button>
            <div class="accordion-content">
              <p v-html="item.body"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>

  </div>
</template>

<script>
import axios from "axios";
import config from "@store/_boot/config";

export default {
  data() {
    return {
      faq: [],
      activeIndex: 0,
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    const items = document.querySelectorAll(".accordion button");

    console.log(items);

    function toggleAccordion() {
      let itemToggle = this.getAttribute('aria-expanded');

      for (let i = 0; i < items.length; i++) {
        items[i].setAttribute('aria-expanded', 'false');
      }

      if (itemToggle == 'false') {
        this.setAttribute('aria-expanded', 'true');
      }
    }

    items.forEach(item => item.addEventListener('click', toggleAccordion));
  },

  methods: {
    getData() {
      axios.get(`${config.HOST}api/footer/get-faq`).then(({data}) => {
        if (data.status) {
          this.faq = data.data;
          console.log("faq----->", this.faq)
        }
      })
    },
    toggleAccordion(id) {
      if (this.activeIndex == id) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = id;
      }
    }
  }
};
</script>