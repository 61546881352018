export const setAudioPath = ({commit}, path) => {
    commit("SET_AUDIO_PATH", path);
};

export const setCheckAudioPath = ({commit}, path) => {
    commit("SET_CHECK_AUDIO_PATH", path);
};

export const setAudioTitle = ({commit}, title) => {
    commit("SET_AUDIO_TITLE", title);
};

export const setAudioCreator = ({commit}, creator) => {
    commit("SET_AUDIO_CREATOR", creator);
};
export const setAudioImage = ({commit}, image) => {
    commit("SET_AUDIO_IMAGE", image);
};

export const setIsPlaying = ({commit}) => {
    commit("SET_IS_PLAYING", true);
};


export const setVolume = ({commit}, volume) => {
    commit("SET_VOLUME", volume);
};


export const setShowPlayer = ({commit}, show) => {
    commit("SET_SHOW_PLAYER", show);
};

export const play = ({commit}) => {
    commit("SET_IS_PLAYING", true);
};

export const pause = ({commit}) => {
    commit("SET_IS_PLAYING", false);
};

export const setPlayList = ({commit}, playList) => {
    commit("SET_PLAY_LIST", playList);
};
export const setCurrentIndex = ({commit}, index) => {
    commit("SET_CURRENT_INDEX", index);
};


export const setCurrentTime = ({commit}, time) => {
    commit("SET_CURRENT_TIME", time);
};
