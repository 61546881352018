<template>
  <div class="search-btv">
    <vue3-simple-typeahead
        :items="items"
        placeholder="Хайх..."
        ref="searchInput"
        id="searchInput"
        :minInputLength="1"
        :minItemLength="1"
        :class="isToggleIcon ? 'toggle' : ''"
        :itemProjection="itemProjection"
        @selectItem="onSearchItemSelected"
        @focusout="focusout"
        @onInput="onInputChange">
      <template #list-item-text="slot">
        <span v-html="slot.itemProjection(slot.item)"></span>
      </template>
    </vue3-simple-typeahead>
    <i @click="clickImage" class="isax isax-search-normal-1"/>
  </div>
</template>

<script>
import api from "../../store/_boot/baseApi"
import config from "../../store/_boot/config";
import {tr} from "../../utils/index";

export default {
  data() {
    return {
      query: "",
      items: [],
      isToggleIcon: false
    }
  },
  methods: {
    tr: tr,
    itemProjection(item) {
      return `<div class="search-item"><img src="${config.IMG_HOST}${item.image}"/> ${item.title.toLowerCase()} <span style="display: none;">${item.keywords}</span><span style="display: none;">${item.original_name}</span></div>`;
    },

    onInputChange(e) {
      api.get(`/api/search?q=${e.input}`).then(({data}) => {
        this.items = data;
        console.log("result: ", this.items);
      });
    },

    onSearchItemSelected(item) {
      this.$router.push('/theatre/detail/' + item.id);
      this.$refs.searchInput.input = '';
    },

    clickImage() {
      this.isToggleIcon = !this.isToggleIcon;
      console.log(this.$refs.searchInput);
      // this.$refs.searchInput.focus();
      document.getElementById('searchInput').focus();
    },

    focusout() {
      this.isToggleIcon = !this.isToggleIcon;
      this.query = "";
    }
  }
};
</script>

<style>
</style>