<template>
  <Header class="hidden lg:flex"/>
  <MobileHeader/>
  <div class="page-container seeroo-app">
    <router-view/>
  </div>
  <Footer/>
  <MobileMenu class="visible lg:invisible"/>
  <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
    <DetailsModal v-if="isModalActive"/>
  </transition>
  <PodcastPlayer :audio-path="audioPath" v-if="showPlayer" ref="audioPlayer" id="playerrr"/>
</template>

<script>
import AOS from "aos";
import {toRefs, watch} from "vue";
import {useQueryProvider} from "vue-query";
import "animate.css";
import Header from "../components/Layout/Header.vue";
import MobileMenu from "../components/Layout/MobileMenu.vue";
import MobileHeader from "../components/Layout/MobileHeader.vue";
import Footer from "../components/Layout/Footer.vue";
import DetailsModal from "../components/Seeroo/DetailsModal.vue";
import {state} from "@store";
import {mapGetters, mapState} from 'vuex';
import PodcastPlayer from "@components/podcast/PodcastPlayer.vue";

export default {
  components: {
    PodcastPlayer,
    Header,
    MobileHeader,
    MobileMenu,
    Footer,
    DetailsModal,
  },
  setup() {
    useQueryProvider({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
        },
      },
    });

    watch(
        () => state.isModalActive,
        () => {
          // setTimeout(function () {
          // window.scrollTo({
          //   left: 0,
          //   top: state.scrollTop,
          // });
          // }, 0);
        }
    );
    return {
      ...toRefs(state),
    };
  },

  data() {
    return {
      isMediaModal: true
    }
  },

  created() {
    setTimeout(() => {
      this.isMediaModal = true;
    })
  },

  mounted() {
    AOS.init();
  },


  computed: {
    ...mapGetters(['showMenu']),
    ...mapState({
      audioPath: (state) => state.audioPath,
      checkAudioPath: (state) => state.checkAudioPath,
      audioTitle: (state) => state.audioTitle,
      audioCreator: (state) => state.audioCreator,
      audioImage: (state) => state.audioImage,
      isPlaying: (state) => state.isPlaying,
      showPlayer: (state) => state.showPlayer,
    }),
    // eslint-disable-next-line vue/no-dupe-keys
    isLoading() {
      return this.isLoading;
    },
  },
};
</script>
