<template>
  <footer class="footer clearfix">
    <div class="container">
      <!-- first footer -->
<!--      <div class="first-footer">-->
<!--        <div class="footer-contact-links-wrapper">-->
<!--          <div class="footer-contact-link-wrapper">-->
<!--            <div class="image-wrapper footer-contact-link-icon">-->
<!--              <div class="icon-footer"><i class="uil-phone-alt"></i></div>-->
<!--            </div>-->
<!--            <div class="footer-contact-link-content">-->
<!--              <h6>Call us</h6>-->
<!--              <p>+971 52-333-4444</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="footer-contact-links-divider"></div>-->
<!--          <div class="footer-contact-link-wrapper">-->
<!--            <div class="image-wrapper footer-contact-link-icon">-->
<!--              <div class="icon-footer"><i class="uil-envelope"></i></div>-->
<!--            </div>-->
<!--            <div class="footer-contact-link-content">-->
<!--              <h6>Write to us</h6>-->
<!--              <p>info@renax.com</p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="footer-contact-links-divider"></div>-->
<!--          <div class="footer-contact-link-wrapper">-->
<!--            <div class="image-wrapper footer-contact-link-icon">-->
<!--              <div class="icon-footer"><i class="uil-location-point"></i></div>-->
<!--            </div>-->
<!--            <div class="footer-contact-link-content">-->
<!--              <h6>Address</h6>-->
<!--              <p>Dubai, Water Tower, Office 123</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!-- second footer -->
      <div class="second-footer">
        <div class="grid grid-cols-12">
          <!-- about & social icons -->
          <div class="col-span-4">
            <div class="widget clearfix">
              <div class="footer-logo"><img src="/img/logo-white.png" alt=""></div>
              <!-- <div class="footer-logo"><h2>CARE<span>X</span></h2></div> -->
              <div class="widget-text">
                <p>Бизнес, эдийн засгийн хамгийн чухал мэдээлэл.</p>
                <div class="social-icons">
                  <ul class="list-inline">
                    <li><a href="#"> <i class="ui uil-facebook"></i></a></li>
                    <li><a href="#"><i class="ui uil-instagram-alt"></i></a></li>
                    <li><a href="#"><i class="ui uil-twitter"></i></a></li>
                    <li><a href="#"><i class="ui uil-youtube"></i></a></li>
                    <li><a href="#"><i class="ui uil-linkedin"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <!-- quick links -->
          <div class="col-span-4">
            <div class="widget clearfix usful-links">
              <h3 class="widget-title">Холбоосууд</h3>
              <ul>
                <li>
                  <router-link to="/content/47" class="link link-hover">Бидний тухай</router-link>
                </li>
                <li>
                  <router-link to="/content/48" class="link link-hover">Үйлчилгээний нөхцөл</router-link>
                </li>
                <li>
                  <router-link to="/content/49" class="link link-hover">Нууцлалын бодлого</router-link>
                </li>
                <li>
                  <router-link to="/faq" class="link link-hover">Түгээмэл асуулт, хариулт</router-link>
                </li>
                <li>
                  <router-link to="/contact" class="link link-hover">Холбоо барих</router-link>
                </li>
              </ul>
            </div>
          </div>
          <!-- subscribe -->
          <div class="col-span-4">
            <div class="widget clearfix">
              <h3 class="widget-title">Аппликейшн татах</h3>
              <p>Гар утасны аппликейшнээ татаад хаанаас ч үзэх боломжтой.</p>
              <div class="widget-app">
                <a href="javascript:void(0)" class="app-link">
                  <i class="uil-google-play"></i>
                  <span>Playstore</span>
                </a>

                <a href="javascript:void(0)" class="app-link">
                  <i class="uil-apple"></i>
                  <span>Appstore</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- bottom footer -->
      <div class="bottom-footer-text copyright">
        <div class="container">
          <p class="mb-0">Copyright © 2024 - All right reserved by Bloomberg TV Mongolia LLC.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

import AppStore from "../../assets/appstore.png";
import PlayStore from "../../assets/playstore.png";
// import axios from "axios";
// import config from "../../store/_boot/config";
import {imageUrl} from "../../utils";

export default {
  data() {
    return {
      AppStore,
      PlayStore,
      contact: [],
      social: [],
      store: [],
      footer_image: '',
      data: {

        phone: '',
        email: '',
        facebook: '',
        youtube: '',
        twitter: '',
      }
    };
  },
  components: {},

  created() {
    // this.getData();
    // this.getSocial();
    // this.getStore();
    // this.getFooterImage();
  },

  methods: {
    imageUrl,
    // getData() {
    //   axios.get(`${config.HOST}/api/footer/contact`).then(({data}) => {
    //     if (data.status) {
    //       this.contact = data.data;
    //     }
    //   })
    // },
    // getSocial() {
    //   axios.get(`${config.HOST}/api/footer/social`).then(({data}) => {
    //     if (data.status) {
    //       this.social = data.data;
    //     }
    //   })
    // },
    // getStore() {
    //   axios.get(`${config.HOST}/api/footer/get-store`).then(({data}) => {
    //     if (data.status) {
    //       this.store = data.data;
    //     }
    //   })
    // },
    // getFooterImage() {
    //   axios.get(`${config.HOST}/api/footer/get-footer-image`).then(({data}) => {
    //     if (data.status) {
    //       this.footer_image = data.data.image;
    //       console.log('this store-------->', this.footer_image);
    //     }
    //   })
    // },
    openDefaultMailClient() {
      window.location.href = 'mailto:example@example.com'; // Change the email address as needed
    }


  }
};
</script>

<style>
.store-logo {
  height: 18px;
  margin-right: 5px;
}
</style>