export const SET_AUDIO_PATH = (state, payload) => {
    state.audioPath = payload;
};

export const SET_CHECK_AUDIO_PATH = (state, payload) => {
    state.checkAudioPath = payload;
};

export const SET_AUDIO_TITLE = (state, payload) => {
    state.audioTitle = payload;
};

export const SET_AUDIO_CREATOR = (state, payload) => {
    state.audioCreator = payload;
};

export const SET_AUDIO_IMAGE = (state, payload) => {
    state.audioImage = payload;
};

export const SET_IS_PLAYING = (state, payload) => {
    state.isPlaying = payload;
};

export const SET_SHOW_PLAYER = (state, payload) => {
    state.showPlayer = payload;
};
export const play = (state) => {
    state.isPlaying = true;
};

export const pause = (state) => {
    state.isPlaying = false;
};
export const SET_PLAY_LIST = (state, payload) => {
    state.playlist = payload;
};

export const SET_CURRENT_INDEX = (state, index) => {
    state.currentTrackIndex = index;
};

export const SET_VOLUME = (state, volume) => {
    state.volume = volume;
};

export const SET_CURRENT_TIME = (state, time) => {
    state.playerCurrentTime = time;
}